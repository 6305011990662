import { APIError, APIResponse } from '../../models/api-response'
import { getLocalToken } from '../auth-service/auth-service'

export function generateHeaders(withToken = false) {
  let headerData: any
  if (withToken) {
    headerData = {
      'Content-Type': 'application/json',
      'Authorization': getLocalToken() as string,
    }
  } else {
    headerData = {
      'Content-Type': 'application/json',
    }
  }
  return new Headers(headerData)
}

export async function buildErrorObject(response: Response) {
  const errObject: APIResponse = await response.json()
  const apiErr: APIError = {
    result: errObject.result,
    status: response.status,
    error: errObject.error,
  }
  return apiErr
}
