//@ts-ignore
import Particles from 'particlesjs'
import { useEffect } from 'react'
import './particles-background.css'

export default function ParticlesBackground() {
  useEffect(() => {
    Particles.init({
      selector: '.particlesBg',
      color: '#ffffff',
      connectParticles: true,
      responsive: [
        {
          breakpoint: 480,
          options: {
            maxParticles: 25,
          },
        },
        {
          breakpoint: 768,
          options: {
            maxParticles: 50,
          },
        },
        {
          breakpoint: 1024,
          options: {
            maxParticles: 75,
          },
        },
      ],
    })
    return () => Particles.destroy()
  }, [])

  return <canvas className="particlesBg"></canvas>
}
