import { Route, useRouteMatch } from 'react-router-dom'
import AdminView from './admin-view/admin-view'
import Admins from './admins/admins'
import styles from './dashboard.module.css'
import Main from './main/main'
import MessagingAnalytics from './messaging-analytics/messaging-analytics'
import PremiumUsers from './premium-users/premium-users'
import Profile from './profile/profile'
import SectionLayout from './section-layout/section-layout'
import Sidebar from './sidebar/sidebar'
import UserView from './user-view/user-view'
import Users from './users/users'

export default function Dashboard() {
  const dashboardRoute = useRouteMatch().path

  return (
    <div className={styles.dashboard}>
      <Sidebar baseRoute={dashboardRoute} />
      <div className={styles.sectionContainer}>
        <Route exact path={`${dashboardRoute}`}>
          <SectionLayout title="Home">
            <Main />
          </SectionLayout>
        </Route>
        <Route path={`${dashboardRoute}/profile`}>
          <SectionLayout title="Profile">
            <Profile />
          </SectionLayout>
        </Route>
        <Route path={`${dashboardRoute}/messaging-analytics`}>
          <SectionLayout title="Messaging Analytics">
            <MessagingAnalytics />
          </SectionLayout>
        </Route>
        <Route exact path={`${dashboardRoute}/users`}>
          <SectionLayout title="Users">
            <Users />
          </SectionLayout>
        </Route>
        <Route path={`${dashboardRoute}/users/show-user/:id`}>
          <SectionLayout title="User View">
            <UserView />
          </SectionLayout>
        </Route>
        <Route path={`${dashboardRoute}/premium-users`}>
          <SectionLayout title="Premium Users">
            <PremiumUsers />
          </SectionLayout>
        </Route>
        <Route exact path={`${dashboardRoute}/admins`}>
          <SectionLayout title="Admins">
            <Admins />
          </SectionLayout>
        </Route>
        <Route path={`${dashboardRoute}/admins/create-admin`}>
          <SectionLayout title="Create Admin">
            <AdminView editMode={false} />
          </SectionLayout>
        </Route>
        <Route path={`${dashboardRoute}/admins/show-admin/:id`}>
          <SectionLayout title="Admin View">
            <AdminView editMode={true} />
          </SectionLayout>
        </Route>
      </div>
    </div>
  )
}
