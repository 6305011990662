import styles from './section-layout.module.css'

type SectionLayoutProps = {
  children: React.ReactNode
  title: string
}
export default function SectionLayout({ children, title }: SectionLayoutProps) {
  return (
    <div className={styles.sectionLayout}>
      <h1 className={styles.sectionTitle}>
        You're in{' '}
        <span className={styles.sectionEmbeddedTitle}>{title} section.</span>
      </h1>
      {children}
    </div>
  )
}
