import { APIError } from '../../models/api-response'
import { logOut } from '../auth-service/auth-service'
import { showModal, showToast } from '../feedback-service/feedback-service'

export async function handleHTTPStatusErrors(
  apiError: APIError,
  resourceName?: string
) {
  switch (apiError.status) {
    case 400:
      switch (apiError.error.name) {
        case 'wrongCredentials':
          showToast(
            'error',
            `Wrong credentials. Please check them and try again!`
          )
          break
        case 'badRequest':
          showToast(
            'error',
            `Body of the request is invalid. Please check all form fields and try again!`
          )
          break
        case 'secondFactorCodeIncorrect':
          showToast('error', `Two-Factor Auth code is incorrect. Try again!`)
          break
        default:
          showToast(
            'error',
            `Server couldn't do the required task. Error (${apiError.error.description})`
          )
          break
      }
      break
    case 403:
      const userAnswer = await showModal(
        'error',
        'Unauthorized access',
        `Your session has expired. Press CONFIRM to LOG IN again.`,
        false
      )
      if (userAnswer.isConfirmed) {
        logOut()
        window.location.replace('/')
      }
      break
    case 404:
      showToast(
        'error',
        `${resourceName || 'Resource'} not found on the server. Error (${
          apiError.error.description
        })`
      )
      break
    case 500:
      showToast(
        'error',
        `An error has occurred in the server. Error (${apiError.error.description})`
      )
      break
    default:
      console.log(apiError)
      break
  }
}

export function handleUnknownErrors(error: Error) {
  showToast(
    'error',
    `Unknown error when connecting to server. Check your internet connection and try again!`
  )
}
