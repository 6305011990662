import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { appContext } from '../../../context/context'
import { useComponentLifeCycleState } from '../../../hooks/hooks'
import { MessagesData } from '../../../models/message'
import { showToast } from '../../../services/feedback-service/feedback-service'
import { getTotalMessages } from '../../../services/messaging-analytics-service/messaging-analytics-service'
import { InputDate } from '../../shared/inputs/inputs'
import MessagesChart from '../../shared/messages-chart/messages-chart'
import styles from './messaging-analytics.module.css'

export default function MessagingAnalytics() {
  const { setLoadingState } = useContext(appContext)
  const [messagesData, setMessagesData] = useState<MessagesData>({
    messages: [],
    totalMessages: 0,
  })
  const [datePicker, setDatePicker] = useState({
    from: moment().subtract(15, 'days').format('yyyy-MM-DD'),
    to: moment().format('yyyy-MM-DD'),
  })
  const lifeCycleRef = useComponentLifeCycleState()

  useEffect(() => {
    document.title = 'Messaging Analytics | i-i'
  }, [])

  useEffect(() => {
    fetchMessagesData()
  }, [datePicker]) //eslint-disable-line

  async function fetchMessagesData() {
    setLoadingState(true)
    if (moment(datePicker.from) < moment(datePicker.to)) {
      const messagesData = await getTotalMessages(
        datePicker.from,
        datePicker.to
      )
      if (lifeCycleRef.current) {
        messagesData && setMessagesData(messagesData)
      }
    } else {
      showToast(
        'error',
        'Start date must be greater than end date. Check it out!'
      )
    }
    setLoadingState(false)
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name
    const value = event.target.value
    setDatePicker((prevState) => ({ ...prevState, [name]: value }))
  }

  return (
    <div className={styles.messagingAnalytics}>
      <div className={styles.datePicker}>
        <label htmlFor="from">From:</label>
        <InputDate
          name="from"
          onChange={handleChange}
          defaultValue={datePicker.from}
        />
        <label htmlFor="to">To:</label>
        <InputDate
          name="to"
          onChange={handleChange}
          defaultValue={datePicker.to}
        />
      </div>
      <MessagesChart type="total-messages" data={messagesData} />
    </div>
  )
}
