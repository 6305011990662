import qrcode from 'qrcode'
import {
  Login2FAParams,
  LoginFirstStep,
  LoginFormData,
  LoginSecondStep,
} from '../../models/login'
import { User } from '../../models/user'
import * as ErrorHandlerService from '../error-handler-service/error-handler-service'
import {
  show2FAModal,
  show2FAQR,
  showPasswordChangeModal,
  showToast,
} from '../feedback-service/feedback-service'
import { buildErrorObject, generateHeaders } from '../http-utils/http-utils'

export async function logIn(formData: LoginFormData): Promise<User | null> {
  async function submitCredentials(
    formData: LoginFormData
  ): Promise<LoginFirstStep | null> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/admins/login`,
        {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: generateHeaders(),
        }
      )
      if (response.status === 200) {
        return await response.json()
      } else {
        const apiErr = await buildErrorObject(response)
        ErrorHandlerService.handleHTTPStatusErrors(apiErr)
        return null
      }
    } catch (error) {
      ErrorHandlerService.handleUnknownErrors(error)
      return null
    }
  }

  async function generateQR(otpauthString: string): Promise<string> {
    const imgSrc = await qrcode.toDataURL(otpauthString)
    return imgSrc
  }

  async function submit2FACode(
    tfaParams: Login2FAParams
  ): Promise<User | null> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/admins/2fa`,
        {
          method: 'POST',
          body: JSON.stringify(tfaParams),
          headers: generateHeaders(),
        }
      )
      if (response.status === 200) {
        const data: LoginSecondStep = await response.json()
        return data.loginData
      } else {
        const apiErr = await buildErrorObject(response)
        ErrorHandlerService.handleHTTPStatusErrors(apiErr)
        return null
      }
    } catch (error) {
      ErrorHandlerService.handleUnknownErrors(error)
      return null
    }
  }

  const loginFirstStep = await submitCredentials(formData)
  if (loginFirstStep) {
    const { admin } = loginFirstStep
    const { qr, base32, firstTime } = loginFirstStep.secondFactorData
    if (firstTime) {
      const qrIMG = await generateQR(qr)
      const user2FAQR = await show2FAQR(qrIMG, base32)
      if (user2FAQR.isConfirmed) {
        const user2FAnswer = await show2FAModal(false)
        if (user2FAnswer.isConfirmed) {
          const user = await submit2FACode({
            userId: admin.id,
            code: user2FAnswer.value,
          })
          if (user) {
            user.token && saveTokenOnLocalStorage(user.token)
            saveUserDataOnLocalStorage(user)
          }
          return user
        } else {
          return null
        }
      } else {
        return null
      }
    } else {
      const user2FAnswer = await show2FAModal(false)
      if (user2FAnswer.isConfirmed) {
        const user = await submit2FACode({
          userId: admin.id,
          code: user2FAnswer.value,
        })
        if (user) {
          user.token && saveTokenOnLocalStorage(user.token)
          saveUserDataOnLocalStorage(user)
        }
        return user
      } else {
        return null
      }
    }
  } else {
    return null
  }
}

export function logOut() {
  localStorage.clear()
}

export async function requestPasswordChange(userId: number) {
  async function changePassword(
    userId: number,
    code: string,
    newPassword: string
  ): Promise<boolean> {
    try {
      const finalResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/admins/2fa/password/change`,
        {
          method: 'POST',
          headers: generateHeaders(true),
          body: JSON.stringify({
            userId,
            code,
            newPassword,
          }),
        }
      )
      if (finalResponse.status === 200) {
        return true
      } else {
        const apiErr = await buildErrorObject(finalResponse)
        ErrorHandlerService.handleHTTPStatusErrors(apiErr)
        return false
      }
    } catch (error) {
      ErrorHandlerService.handleUnknownErrors(error)
      return false
    }
  }

  const user2FAnswer = await show2FAModal(true)
  if (user2FAnswer.isConfirmed) {
    const passwordChangeAnswer = await showPasswordChangeModal()
    if (passwordChangeAnswer.isConfirmed) {
      const result = await changePassword(
        userId,
        user2FAnswer.value,
        passwordChangeAnswer.value as string
      )
      if (result) {
        showToast('success', 'Password successfully changed!')
      }
    }
  }
}

export function getLocalToken(): string | null {
  return localStorage.getItem('token')
}

export function getLocalUserData(): User | null {
  const userData = localStorage.getItem('userData')
  if (userData) {
    return JSON.parse(userData) as User
  } else {
    return null
  }
}

function saveTokenOnLocalStorage(token: string) {
  localStorage.setItem('token', token)
}

function saveUserDataOnLocalStorage(rawUserData: User) {
  const userDataWithoutToken = { ...rawUserData }
  delete userDataWithoutToken.token
  localStorage.setItem('userData', JSON.stringify(userDataWithoutToken))
}
