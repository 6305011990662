import { useContext } from 'react'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import Dashboard from './components/dashboard/dashboard'
import Login from './components/login/login'
import Spinner from './components/shared/spinner/spinner'
import { appContext } from './context/context'

function App() {
  const context = useContext(appContext)

  return (
    <>
      <Router>
        <Route exact path="/">
          <Login />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        {context.authStatus ? (
          <Redirect to="/dashboard" />
        ) : (
          <Redirect exact to="/" />
        )}
      </Router>
      {context.isSomethingLoading && <Spinner />}
    </>
  )
}

export default App
