import Tippy from '@tippyjs/react'
import { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { appContext } from '../../../context/context'
import { useComponentLifeCycleState } from '../../../hooks/hooks'
import { AppUser } from '../../../models/user'
import {
  deleteUser,
  getUsers,
  requestPasswordForUser,
  setAsPremiumUser,
  unsetUserAsPremium,
} from '../../../services/users-service/users-service'
import Table from '../../shared/table/table'
import styles from './users.module.css'

export default function Users() {
  const { setLoadingState } = useContext(appContext)
  const [usersList, setUsersList] = useState<AppUser[]>([])
  const usersRoute = useRouteMatch().path
  const lifeCycleRef = useComponentLifeCycleState()
  const history = useHistory()

  useEffect(() => {
    document.title = 'Users | i-i'
  }, [])

  useEffect(() => {
    fetchUsersList()
  }, []) //eslint-disable-line

  async function fetchUsersList() {
    setLoadingState(true)
    const users = await getUsers()
    if (lifeCycleRef.current) {
      users && setUsersList(users)
    }
    setLoadingState(false)
  }

  function viewUser(user: AppUser) {
    history.push(`${usersRoute}/show-user/${user.id}`)
  }

  async function setUserAsPremium(user: AppUser) {
    setLoadingState(true)
    await setAsPremiumUser(user)
    await fetchUsersList()
    setLoadingState(false)
  }

  async function removeUserFromPremium(user: AppUser) {
    setLoadingState(true)
    await unsetUserAsPremium(user)
    await fetchUsersList()
    setLoadingState(false)
  }

  async function removeUser(user: AppUser) {
    setLoadingState(true)
    await deleteUser(user)
    setLoadingState(false)
    fetchUsersList()
  }

  async function requestUserPassword(email: string) {
    setLoadingState(true)
    await requestPasswordForUser(email)
    setLoadingState(false)
  }

  return (
    <div className={styles.users}>
      <Table heads={['ID', 'User Name', 'Phone', 'Actions']}>
        {(rowStyle, cellStyle, actionCellStyle) =>
          usersList.map((user) => (
            <tr className={rowStyle} key={user.id}>
              <td className={cellStyle}>{user.id}</td>
              <td className={cellStyle} onClick={() => viewUser(user)}>
                {user.username}
              </td>
              <td className={cellStyle}>{user.phoneNumber}</td>
              <td className={actionCellStyle}>
                {user.id === -2 || (
                  <>
                    <Tippy
                      duration={0}
                      content={
                        <span className={styles.actionButtonTooltip}>
                          View user
                        </span>
                      }
                    >
                      <Link
                        to={`${usersRoute}/show-user/${user.id}`}
                        className={`${styles.actionButton} ${styles.editButton}`}
                      >
                        <i className="fas fa-eye"></i>
                      </Link>
                    </Tippy>
                    <Tippy
                      duration={0}
                      content={
                        <span className={styles.actionButtonTooltip}>
                          Request new password for this user
                        </span>
                      }
                    >
                      <button
                        type="button"
                        onClick={() => requestUserPassword(user.email)}
                        className={`${styles.actionButton} ${styles.requestUserPassword}`}
                      >
                        <i className="fas fa-key"></i>
                      </button>
                    </Tippy>
                    <Tippy
                      duration={0}
                      content={
                        <span className={styles.actionButtonTooltip}>
                          Delete user
                        </span>
                      }
                    >
                      <button
                        type="button"
                        onClick={() => removeUser(user)}
                        className={`${styles.actionButton} ${styles.deleteButton}`}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </Tippy>
                    {user.isPremium ? (
                      <Tippy
                        duration={0}
                        content={
                          <span className={styles.actionButtonTooltip}>
                            Remove Premium from user
                          </span>
                        }
                      >
                        <button
                          type="button"
                          onClick={() => removeUserFromPremium(user)}
                          className={`${styles.actionButton} ${styles.unsetPremiumButton}`}
                        >
                          <i className="far fa-star"></i>
                        </button>
                      </Tippy>
                    ) : (
                      <Tippy
                        duration={0}
                        content={
                          <span className={styles.actionButtonTooltip}>
                            Set user as Premium
                          </span>
                        }
                      >
                        <button
                          type="button"
                          onClick={() => setUserAsPremium(user)}
                          className={`${styles.actionButton} ${styles.setPremiumButton}`}
                        >
                          <i className="fas fa-star"></i>
                        </button>
                      </Tippy>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))
        }
      </Table>
    </div>
  )
}
