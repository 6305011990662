import Chartist from 'chartist'
import 'chartist-plugin-pointlabels'
import moment from 'moment'
import { useEffect } from 'react'
import { MessagesChartData, MessagesData } from '../../../models/message'
import './ct-chart.css'
import styles from './messages-chart.module.css'

type MessagesChartProps = {
  data: MessagesData
  type: 'user-messages' | 'total-messages'
}
export default function MessagesChart({ data, type }: MessagesChartProps) {
  const chartData: MessagesChartData = {
    labels: data.messages.map((message) => message.createdAt),
    series: [data.messages.map((message) => message.numberMessage)],
  }

  useEffect(() => {
    const chart = new Chartist.Line('.ct-chart', chartData, {
      axisX: {
        labelInterpolationFnc: (value: string) => {
          return moment(value).format('MMM D')
        },
      },
      axisY: {
        onlyInteger: true,
      },
      plugins: [
        Chartist.plugins.ctPointLabels({
          textAnchor: 'middle',
          labelClass: 'ct-point-label',
          labelInterpolationFnc: (value: number) => {
            return value || 0
          },
        }),
      ],
    })
    return () => {
      chart.detach()
    }
  })

  return (
    <div className={styles.messagesChartContainer}>
      <div className={styles.messagesChartTitleContainer}>
        <span className={styles.messagesChartTitle}>Messages Chart</span>
        <small className={styles.messagesChartLabel}>
          {data.totalMessages || 0} messages sent{' '}
          {type === 'total-messages' ? 'on i-i' : 'by this user'} in the last{' '}
          {chartData.labels.length} days.
        </small>
      </div>
      <div className="ct-chart"></div>
    </div>
  )
}
