import { APIResponse } from '../../models/api-response'
import { AppPremiumUser, AppUser, AppUserParams } from '../../models/user'
import * as ErrorHandlerService from '../error-handler-service/error-handler-service'
import { showModal, showToast } from '../feedback-service/feedback-service'
import { buildErrorObject, generateHeaders } from '../http-utils/http-utils'

export async function getUsers(): Promise<AppUser[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/users`,
      {
        headers: generateHeaders(true),
      }
    )
    if (response.status === 200) {
      const apiRes: APIResponse = await response.json()
      return (apiRes.usersList as AppUser[]).sort(
        (user1, user2) => user1.id - user2.id
      )
    } else {
      const apiErr = await buildErrorObject(response)
      ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'Users list')
      return null
    }
  } catch (error) {
    ErrorHandlerService.handleUnknownErrors(error)
    return null
  }
}

export async function getUser(userId: number): Promise<AppUser | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/users/${userId}`,
      {
        headers: generateHeaders(true),
      }
    )
    if (response.status === 200) {
      const apiRes: APIResponse = await response.json()
      return apiRes.user as AppUser
    } else {
      const apiErr = await buildErrorObject(response)
      ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'User')
      return null
    }
  } catch (error) {
    ErrorHandlerService.handleUnknownErrors(error)
    return null
  }
}

export async function getPremiumUsers(): Promise<AppPremiumUser[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/users/premium`,
      {
        headers: generateHeaders(true),
      }
    )
    if (response.status === 200) {
      const apiRes: APIResponse = await response.json()
      return (apiRes.usersList as AppPremiumUser[]).sort(
        (user1, user2) => +user1.userId - +user2.userId
      )
    } else {
      const apiErr = await buildErrorObject(response)
      ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'Premium users list')
      return null
    }
  } catch (error) {
    ErrorHandlerService.handleUnknownErrors(error)
    return null
  }
}

export async function editUser(
  userId: number,
  updateParam: AppUserParams
): Promise<boolean> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/users/${userId}`,
      {
        method: 'PUT',
        headers: generateHeaders(true),
        body: JSON.stringify({
          userId,
          updateParam,
        }),
      }
    )
    if (response.status === 200) {
      showToast('success', 'User successfully edited!')
      return true
    } else {
      const apiErr = await buildErrorObject(response)
      ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'User')
      return false
    }
  } catch (error) {
    ErrorHandlerService.handleUnknownErrors(error)
    return false
  }
}

export async function deleteUser(user: AppUser): Promise<boolean> {
  const userResponse = await showModal(
    'warning',
    'WARNING!',
    `You are about to DELETE ${user.username} from users database. Are you sure you want to proceed?`,
    true
  )
  if (userResponse.isConfirmed) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/users/${user.id}`,
        {
          method: 'DELETE',
          headers: generateHeaders(true),
        }
      )
      if (response.status === 200) {
        showToast('success', 'User successfully deleted')
        return true
      } else {
        const apiErr = await buildErrorObject(response)
        ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'User')
        return false
      }
    } catch (error) {
      ErrorHandlerService.handleUnknownErrors(error)
      return false
    }
  } else {
    return false
  }
}

export async function setAsPremiumUser(user: AppUser): Promise<boolean> {
  const userResponse = await showModal(
    'question',
    'Confirmation',
    `You are about to SET ${user.username} as Premium. Are you sure you want to proceed?`,
    true
  )
  if (userResponse.isConfirmed) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/users/${user.id}/premium/set`,
        {
          method: 'PUT',
          headers: generateHeaders(true),
          body: JSON.stringify({ userId: user.id }),
        }
      )
      if (response.status === 200) {
        showToast('success', 'User successfully set to Premium!')
        return true
      } else {
        const apiErr = await buildErrorObject(response)
        ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'User')
        return false
      }
    } catch (error) {
      ErrorHandlerService.handleUnknownErrors(error)
      return false
    }
  } else {
    return false
  }
}

export async function unsetUserAsPremium(user: AppUser): Promise<boolean> {
  const userResponse = await showModal(
    'warning',
    'Warning!',
    `You are about to REMOVE ${user.username} from Premium list. Are you sure you want to proceed?`,
    true
  )
  if (userResponse.isConfirmed) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/users/${user.id}/premium/remove`,
        {
          method: 'PUT',
          headers: generateHeaders(true),
          body: JSON.stringify({}),
        }
      )
      if (response.status === 200) {
        showToast('success', 'User successfully removed from Premium.')
        return true
      } else {
        const apiErr = await buildErrorObject(response)
        ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'User')
        return false
      }
    } catch (error) {
      ErrorHandlerService.handleUnknownErrors(error)
      return false
    }
  } else {
    return false
  }
}

export async function requestPasswordForUser(email: string): Promise<boolean> {
  const confirmation = await showModal(
    'question',
    'Confirmation',
    `You are about to REQUEST a NEW PASSWORD for ${email}. Are you sure you want to proceed?`,
    true
  )
  if (confirmation.isConfirmed) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/forgot/password/request`,
        {
          method: 'POST',
          headers: generateHeaders(true),
          body: JSON.stringify({ email }),
        }
      )
      if (response.status === 200) {
        showToast('success', `Password request has been made for ${email}.`)
        return true
      } else {
        const apiErr = await buildErrorObject(response)
        ErrorHandlerService.handleHTTPStatusErrors(apiErr)
        return false
      }
    } catch (error) {
      ErrorHandlerService.handleUnknownErrors(error)
      return false
    }
  } else {
    return false
  }
}
