import { Admin, AdminParams } from '../../models/admin'
import { APIResponse } from '../../models/api-response'
import * as ErrorHandlerService from '../error-handler-service/error-handler-service'
import { showModal, showToast } from '../feedback-service/feedback-service'
import { buildErrorObject, generateHeaders } from '../http-utils/http-utils'

export async function getAdmins(): Promise<Admin[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/admins`,
      {
        headers: generateHeaders(true),
      }
    )
    if (response.status === 200) {
      const apiRes: APIResponse = await response.json()
      return (apiRes.adminsList as Admin[]).sort(
        (admin1, admin2) => admin1.id - admin2.id
      )
    } else {
      const apiErr = await buildErrorObject(response)
      ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'Admins list')
      return null
    }
  } catch (error) {
    ErrorHandlerService.handleUnknownErrors(error)
    return null
  }
}

export async function getAdmin(adminId: number): Promise<Admin | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/admins/${adminId}`,
      {
        headers: generateHeaders(true),
      }
    )
    if (response.status === 200) {
      const apiRes: APIResponse = await response.json()
      return apiRes.admin as Admin
    } else {
      const apiErr = await buildErrorObject(response)
      ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'Admin')
      return null
    }
  } catch (error) {
    ErrorHandlerService.handleUnknownErrors(error)
    return null
  }
}

export async function createAdmin(adminParams: AdminParams): Promise<boolean> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/admins/register`,
      {
        method: 'POST',
        headers: generateHeaders(true),
        body: JSON.stringify(adminParams),
      }
    )
    if (response.status === 200) {
      showToast('success', 'Admin successfully created!')
      return true
    } else {
      const apiErr = await buildErrorObject(response)
      ErrorHandlerService.handleHTTPStatusErrors(apiErr)
      return false
    }
  } catch (error) {
    ErrorHandlerService.handleUnknownErrors(error)
    return false
  }
}

export async function editAdmin(
  adminId: number,
  updateParam: AdminParams
): Promise<boolean> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/admins/${adminId}`,
      {
        method: 'PUT',
        headers: generateHeaders(true),
        body: JSON.stringify({
          updateParam,
        }),
      }
    )
    if (response.status === 200) {
      showToast('success', 'Admin successfully updated!')
      return true
    } else {
      const apiErr = await buildErrorObject(response)
      ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'Admin')
      return false
    }
  } catch (error) {
    ErrorHandlerService.handleUnknownErrors(error)
    return false
  }
}

export async function deleteAdmin(admin: Admin): Promise<boolean> {
  const userResponse = await showModal(
    'warning',
    'WARNING!',
    `You are about to DELETE ${admin.fullName} from admins group. Are you sure you want to proceed?`,
    true
  )
  if (userResponse.isConfirmed) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/admins/${admin.id}`,
        {
          method: 'DELETE',
          headers: generateHeaders(true),
        }
      )
      if (response.status === 200) {
        showToast('success', 'Admin successfully deleted.')
        return true
      } else {
        const apiErr = await buildErrorObject(response)
        ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'Admin')
        return false
      }
    } catch (error) {
      ErrorHandlerService.handleUnknownErrors(error)
      return false
    }
  } else {
    return false
  }
}
