import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { appContext } from '../../../context/context'
import { useComponentLifeCycleState } from '../../../hooks/hooks'
import { MessagesData } from '../../../models/message'
import { getTotalMessages } from '../../../services/messaging-analytics-service/messaging-analytics-service'
import MessagesChart from '../../shared/messages-chart/messages-chart'
import styles from './main.module.css'

export default function Main() {
  const { setLoadingState } = useContext(appContext)
  const [messagesData, setMessagesData] = useState<MessagesData>({
    messages: [],
    totalMessages: 0,
  })
  const lifeCycleRef = useComponentLifeCycleState()

  useEffect(() => {
    document.title = 'Home | i-i'
  }, [])

  useEffect(() => {
    fetchTotalMessages()
  }, []) //eslint-disable-line

  async function fetchTotalMessages() {
    setLoadingState(true)
    const messagesData = await getTotalMessages(
      moment().subtract(14, 'days').format('yyyy-MM-DD'),
      moment().format('yyyy-MM-DD')
    )
    if (lifeCycleRef.current) {
      messagesData && setMessagesData(messagesData)
    }
    setLoadingState(false)
  }

  return (
    <div className={styles.main}>
      <MessagesChart type="total-messages" data={messagesData} />
    </div>
  )
}
