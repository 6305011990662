import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appContext } from '../../../context/context'
import {
  useComponentLifeCycleState,
  useInputChangeHandler,
} from '../../../hooks/hooks'
import { AdminParams } from '../../../models/admin'
import {
  createAdmin,
  editAdmin,
  getAdmin,
} from '../../../services/admins-service/admins-service'
import { PrimaryButton, SecondaryButton } from '../../shared/buttons/buttons'
import {
  InputEmail,
  InputPassword,
  InputPhone,
  InputText,
} from '../../shared/inputs/inputs'
import styles from './admin-view.module.css'

type AdminCreatorProps = {
  editMode: boolean
}
export default function AdminView({ editMode }: AdminCreatorProps) {
  const { setLoadingState } = useContext(appContext)
  const routeParams = useParams() as any
  const routeAdminId = parseInt(routeParams.id)
  const history = useHistory()
  const [
    adminParams,
    setAdminParams,
    setAdminInitialValues,
  ] = useInputChangeHandler<AdminParams>({
    fullName: '',
    email: '',
    phone: '',
  })
  const [formDisabledState, setFormDisabledState] = useState(true)
  const lifeCycleRef = useComponentLifeCycleState()

  useEffect(() => {
    document.title = editMode
      ? `View admin #${routeAdminId} | i-i`
      : 'Create admin | i-i'
  }, []) //eslint-disable-line

  useEffect(() => {
    if (editMode) {
      fetchAdmin()
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!editMode) {
      setFormDisabledState(false)
    }
  }, []) //eslint-disable-line

  async function fetchAdmin() {
    setLoadingState(true)
    const admin = await getAdmin(routeAdminId)
    if (lifeCycleRef.current) {
      admin &&
        setAdminInitialValues({
          fullName: admin.fullName,
          email: admin.email,
          phone: admin.phone,
        })
    }
    setLoadingState(false)
  }

  async function submitChanges(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setLoadingState(true)
    const result = editMode
      ? await editAdmin(routeAdminId, adminParams)
      : await createAdmin(adminParams)
    result && returnToAdmins()
    setLoadingState(false)
  }

  function returnToAdmins() {
    history.push('/dashboard/admins')
  }

  return (
    <div className={styles.editAdmin}>
      {editMode && formDisabledState ? (
        <PrimaryButton
          type="button"
          onClick={() => setFormDisabledState(false)}
          className={styles.enableFormButton}
        >
          <i className="fas fa-edit"></i> EDIT THIS ADMIN
        </PrimaryButton>
      ) : null}
      <form
        className={styles.editAdminForm}
        onSubmit={submitChanges}
        noValidate
      >
        <label htmlFor="fullName">Full name</label>
        <InputText
          name="fullName"
          defaultValue={adminParams.fullName}
          required
          onChange={setAdminParams}
          placeholder="e.g Alexander Derulo"
          disabled={formDisabledState}
        />
        <label htmlFor="email">E-mail Address</label>
        <InputEmail
          name="email"
          defaultValue={adminParams.email}
          required
          onChange={setAdminParams}
          placeholder="e.g useremail@domain.com"
          disabled={formDisabledState}
        />
        {editMode || (
          <>
            <label htmlFor="password">Password</label>
            <InputPassword
              name="password"
              required
              onChange={setAdminParams}
              placeholder="Enter admin's password here."
              disabled={formDisabledState}
            />
          </>
        )}
        <label htmlFor="phone">Phone number</label>
        <InputPhone
          name="phone"
          defaultValue={adminParams.phone}
          onChange={setAdminParams}
          required
          placeholder="e.g +1 3748559412"
          disabled={formDisabledState}
        />
        <div className={styles.editAdminFormButtonsContainer}>
          <SecondaryButton type="button" onClick={returnToAdmins}>
            <i className="fas fa-times-circle"></i> CANCEL
          </SecondaryButton>
          <PrimaryButton disabled={formDisabledState}>
            <i className="fas fa-save"></i> SAVE CHANGES
          </PrimaryButton>
        </div>
      </form>
    </div>
  )
}
