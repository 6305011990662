import { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { appContext } from '../../../context/context'
import { useInteractiveMenu } from '../../../hooks/hooks'
import { logOut } from '../../../services/auth-service/auth-service'
import styles from './sidebar.module.css'

type SidebarProps = { baseRoute: string }
export default function Sidebar({ baseRoute }: SidebarProps) {
  const [isSidebarOpen, toggleSidebar] = useInteractiveMenu(768)
  const [userHasScrolled, setUserHasScrolled] = useState<boolean>(false)
  const context = useContext(appContext)

  useEffect(() => {
    window.addEventListener('scroll', setToggleButtonAnimation)
    return () => {
      window.removeEventListener('scroll', setToggleButtonAnimation)
    }
  })

  function hideMenuButtonOnScroll() {
    if (window.scrollY > 40) {
      setUserHasScrolled(true)
      window.requestAnimationFrame(hideMenuButtonOnScroll)
    } else {
      setUserHasScrolled(false)
      window.requestAnimationFrame(hideMenuButtonOnScroll)
    }
  }

  function setToggleButtonAnimation() {
    window.requestAnimationFrame(hideMenuButtonOnScroll)
  }

  function logOutUser() {
    logOut()
    context.setUserData(null)
    context.toggleAuthStatus(false)
  }

  return (
    <>
      <button
        type="button"
        onClick={toggleSidebar}
        className={`${styles.sidebarButton} ${styles.sidebarToggleButton} ${
          userHasScrolled || styles.sidebarToggleButtonShown
        }`}
      >
        <i className="fas fa-bars"></i>
      </button>
      <div
        className={`${styles.sidebar} ${
          isSidebarOpen ? styles.sidebarOpened : ''
        }`}
      >
        <NavLink
          to={`${baseRoute}/profile`}
          className={`${styles.sidebarButton} ${styles.profileButton}`}
          activeClassName={styles.sidebarButtonActive}
          onClick={toggleSidebar}
          title={`View ${context.userData?.fullName}'s profile`}
        >
          <i className="far fa-user"></i>
          <span className={styles.sidebarButtonText}>
            {context.userData?.fullName.split(' ').slice(0, 2).join(' ')}
          </span>
        </NavLink>
        <NavLink
          exact
          to={baseRoute}
          className={`${styles.sidebarButton} ${styles.homeButton}`}
          activeClassName={styles.sidebarButtonActive}
          onClick={toggleSidebar}
        >
          <i className="fas fa-home"></i>
          <span className={styles.sidebarButtonText}>Home</span>
        </NavLink>
        <NavLink
          to={`${baseRoute}/messaging-analytics`}
          className={`${styles.sidebarButton} ${styles.messagingAnalyticsButton}`}
          activeClassName={styles.sidebarButtonActive}
          onClick={toggleSidebar}
        >
          <i className="fas fa-chart-line"></i>
          <span className={styles.sidebarButtonText}>Messaging Analytics</span>
        </NavLink>
        <NavLink
          to={`${baseRoute}/admins`}
          className={`${styles.sidebarButton} ${styles.adminsButton}`}
          activeClassName={styles.sidebarButtonActive}
          onClick={toggleSidebar}
        >
          <i className="fas fa-user-cog"></i>
          <span className={styles.sidebarButtonText}>Admins</span>
        </NavLink>
        <NavLink
          to={`${baseRoute}/users`}
          className={`${styles.sidebarButton} ${styles.usersButton}`}
          activeClassName={styles.sidebarButtonActive}
          onClick={toggleSidebar}
        >
          <i className="fas fa-users"></i>
          <span className={styles.sidebarButtonText}>Users</span>
        </NavLink>
        <NavLink
          to={`${baseRoute}/premium-users`}
          className={`${styles.sidebarButton} ${styles.premiumUsers}`}
          activeClassName={styles.sidebarButtonActive}
          onClick={toggleSidebar}
        >
          <i className="fas fa-user-tie"></i>
          <span className={styles.sidebarButtonText}>Premium Users</span>
        </NavLink>
        <button
          type="button"
          title="Log out"
          className={`${styles.sidebarButton} ${styles.logoutButton}`}
          onClick={logOutUser}
        >
          <i className="fas fa-power-off"></i>
          <span className={styles.sidebarButtonText}>LOG OUT</span>
        </button>
      </div>
    </>
  )
}
