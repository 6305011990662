import { useContext, useEffect } from 'react'
import { ReactComponent as UserDefaultAvatar } from '../../../assets/user-default.svg'
import { appContext } from '../../../context/context'
import { requestPasswordChange } from '../../../services/auth-service/auth-service'
import { PrimaryButton } from '../../shared/buttons/buttons'
import styles from './profile.module.css'

export default function Profile() {
  const { userData, setLoadingState } = useContext(appContext)

  useEffect(() => {
    document.title = 'Profile | i-i'
  }, [])

  async function startPasswordChange() {
    setLoadingState(true)
    await requestPasswordChange(userData?.id as number)
    setLoadingState(false)
  }

  return (
    <div className={styles.profile}>
      <div className={styles.profileCard}>
        <div className={styles.userAvatarCropper}>
          <UserDefaultAvatar className={styles.userAvatar} />
        </div>
        <div className={styles.userInformationContainer}>
          <div className={styles.userInformationRow}>
            <span className={styles.userInformationLabel}>Name:</span>
            <span className={styles.userInformationData}>
              {userData?.fullName}
            </span>
          </div>
          <div className={styles.userInformationRow}>
            <span className={styles.userInformationLabel}>Email:</span>
            <span className={styles.userInformationData}>
              {userData?.email}
            </span>
          </div>
          <div className={styles.userInformationRow}>
            <span className={styles.userInformationLabel}>Phone:</span>
            <span className={styles.userInformationData}>
              {userData?.phone}
            </span>
          </div>
        </div>
        <PrimaryButton type="button" onClick={startPasswordChange}>
          <i className="fas fa-edit"></i> CHANGE PASSWORD
        </PrimaryButton>
      </div>
    </div>
  )
}
