import { useEffect, useRef, useState } from 'react'

/**
 * Injects responsive mobile menu behavior for Menu components.
 *
 * @param {number} widthBreakPoint - Window width breakpoint. It's used
 * to handle mobile and desktop behaviors based on window width, e.g if you
 * only want to have an hamburger-like menu behavior in mobile and a navbar
 * behavior in desktop using the same component.
 * @returns A boolean flag state variable and a toggle function. Boolean flag
 * can be used to determine e.g if a menu is shown or hidden. Toggle function
 * can be used e.g in open and close buttons.
 *
 * @example
 * function Menu() {
 *   const [isMenuOpen, showHideMenu] = useInteractiveMenu(768)
 *
 *   return (
 *     <>
 *       <button onClick={showHideMenu}>
 *         {isMenuOpen ? 'CLOSE MENU' : 'OPEN MENU'}
 *       </button>
 *       <nav className={`menu--${isMenuOpen ? 'shown' : 'hidden'}`}>
 *         <ul>
 *           <li>ITEM</li>
 *           <li>ITEM</li>
 *           <li>ITEM</li>
 *         </ul>
 *       </nav>
 *     </>
 *   )
 * }
 */
export function useInteractiveMenu(
  widthBreakPoint: number
): [boolean, () => void] {
  const [isOpen, setIsOpen] = useState(false)

  function toggleShowMenu() {
    if (window.outerWidth < widthBreakPoint) {
      setIsOpen((state) => !state)
    }
  }

  function detectWindowWidth() {
    if (window.outerWidth >= widthBreakPoint) {
      setIsOpen(true)
    }
  }

  useEffect(() => {
    detectWindowWidth()
    window.addEventListener('resize', detectWindowWidth)
    return () => window.removeEventListener('resize', detectWindowWidth)
  })

  return [isOpen, toggleShowMenu]
}

export function useComponentLifeCycleState() {
  const lifeCycleRef = useRef<boolean>(false)

  useEffect(() => {
    lifeCycleRef.current = true
    return () => {
      lifeCycleRef.current = false
    }
  }, [])

  return lifeCycleRef
}

export function useInputChangeHandler<T>(
  formDataShape: T
): [
  T,
  (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
  (data: T) => void
] {
  const [formData, setFormData] = useState(formDataShape)

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    const name = event.target.name
    const value = event.target.value
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }

  function setInitialValue(data: T) {
    setFormData(data)
  }

  return [formData, handleChange, setInitialValue]
}
