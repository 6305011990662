import { createContext, useEffect, useState } from 'react'
import { AppContext } from '../models/app-context'
import { User } from '../models/user'
import {
  getLocalToken,
  getLocalUserData,
} from '../services/auth-service/auth-service'

const contextData: AppContext = {
  authStatus: false,
  userData: null,
  toggleAuthStatus() {},
  setUserData() {},
  isSomethingLoading: false,
  setLoadingState() {},
}
export const appContext = createContext(contextData)

type AppContextProps = {
  children: React.ReactNode
}
export function AppContextProvider(props: AppContextProps) {
  const [isUserLoggedIn, toggleAuthStatus] = useState<boolean>(false)
  const [userData, setUserData] = useState<User | null>(null)
  const [isSomethingLoading, setLoadingState] = useState<boolean>(false)

  useEffect(() => {
    const localToken = getLocalToken()
    const localUserData = getLocalUserData()
    if (localToken && localUserData) {
      toggleAuthStatus(true)
      setUserData(localUserData)
    }
  }, []) //eslint-disable-line

  return (
    <appContext.Provider
      value={{
        authStatus: isUserLoggedIn,
        userData,
        toggleAuthStatus,
        setUserData,
        isSomethingLoading,
        setLoadingState,
      }}
    >
      {props.children}
    </appContext.Provider>
  )
}
