import { APIResponse } from '../../models/api-response'
import { Message, MessagesData } from '../../models/message'
import * as ErrorHandlerService from '../error-handler-service/error-handler-service'
import { buildErrorObject, generateHeaders } from '../http-utils/http-utils'

export async function getTotalMessages(
  from: string,
  to: string
): Promise<MessagesData | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/messages/${from}/${to}`,
      {
        headers: generateHeaders(true),
      }
    )
    if (response.status === 200) {
      const apiRes: APIResponse = await response.json()
      const messagesData: MessagesData = {
        messages: apiRes.message as Message[],
        totalMessages: apiRes.totalMessages as number,
      }
      return messagesData
    } else {
      const apiErr = await buildErrorObject(response)
      ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'Total messages data')
      return null
    }
  } catch (error) {
    ErrorHandlerService.handleUnknownErrors(error)
    return null
  }
}

export async function getUserMessages(
  userId: number,
  from: string,
  to: string
): Promise<MessagesData | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/messages/${userId}/${from}/${to}`,
      {
        headers: generateHeaders(true),
      }
    )
    if (response.status === 200) {
      const apiRes: APIResponse = await response.json()
      const messagesData: MessagesData = {
        messages: apiRes.message as Message[],
        totalMessages: apiRes.totalMessages as number,
      }
      return messagesData
    } else {
      const apiErr = await buildErrorObject(response)
      ErrorHandlerService.handleHTTPStatusErrors(apiErr, 'User messages data')
      return null
    }
  } catch (error) {
    ErrorHandlerService.handleUnknownErrors(error)
    return null
  }
}
