import Tippy from '@tippyjs/react'
import { useContext, useEffect, useState } from 'react'
import { appContext } from '../../../context/context'
import { useComponentLifeCycleState } from '../../../hooks/hooks'
import { AppPremiumUser } from '../../../models/user'
import {
  getPremiumUsers,
  unsetUserAsPremium,
} from '../../../services/users-service/users-service'
import Table from '../../shared/table/table'
import styles from './premium-users.module.css'

export default function PremiumUsers() {
  const { setLoadingState } = useContext(appContext)
  const [premiumUsers, setPremiumUsers] = useState<AppPremiumUser[]>([])
  const lifeCycleRef = useComponentLifeCycleState()

  useEffect(() => {
    document.title = 'Premium Users | i-i'
  }, [])

  useEffect(() => {
    fetchPremiumUsers()
  }, []) //eslint-disable-line

  async function fetchPremiumUsers() {
    setLoadingState(true)
    const premiumUsers = await getPremiumUsers()
    if (lifeCycleRef.current) {
      premiumUsers && setPremiumUsers(premiumUsers)
    }
    setLoadingState(false)
  }

  async function removeUserFromPremium(premiumUser: AppPremiumUser) {
    setLoadingState(true)
    const result = await unsetUserAsPremium(premiumUser.user)
    result && fetchPremiumUsers()
    setLoadingState(false)
  }

  return (
    <div className={styles.premiumUsers}>
      <Table heads={['ID', 'User Name', 'Phone', 'Actions']}>
        {(rowStyle, cellStyle, actionCellStyle) =>
          premiumUsers.map((premiumUser) => (
            <tr className={rowStyle} key={premiumUser.userId}>
              <td className={cellStyle}>{premiumUser.userId}</td>
              <td className={cellStyle}>{premiumUser.user.username}</td>
              <td className={cellStyle}>{premiumUser.user.phoneNumber}</td>
              <td className={actionCellStyle}>
                <Tippy
                  duration={0}
                  content={
                    <span className={styles.actionButtonTooltip}>
                      Remove user from Premium
                    </span>
                  }
                >
                  <button
                    type="button"
                    onClick={() => removeUserFromPremium(premiumUser)}
                    className={`${styles.actionButton} ${styles.removeUserFromPremiumButton}`}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </Tippy>
              </td>
            </tr>
          ))
        }
      </Table>
    </div>
  )
}
