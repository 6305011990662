import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appContext } from '../../../context/context'
import {
  useComponentLifeCycleState,
  useInputChangeHandler,
} from '../../../hooks/hooks'
import { MessagesData } from '../../../models/message'
import { AppUser, AppUserParams } from '../../../models/user'
import { getUserMessages } from '../../../services/messaging-analytics-service/messaging-analytics-service'
import {
  editUser,
  getUser,
  setAsPremiumUser,
  unsetUserAsPremium,
} from '../../../services/users-service/users-service'
import {
  PrimaryButton,
  SecondaryButton,
  SuccessButton,
  WarningButton,
} from '../../shared/buttons/buttons'
import { InputEmail, InputPhone, InputText } from '../../shared/inputs/inputs'
import MessagesChart from '../../shared/messages-chart/messages-chart'
import styles from './user-view.module.css'

export default function UserView() {
  const { setLoadingState } = useContext(appContext)
  const routeUserId = parseInt((useParams() as any).id as string)
  const history = useHistory()
  const [user, setUser] = useState<AppUser>()
  const [
    userParams,
    setUserParams,
    setInitialUserData,
  ] = useInputChangeHandler<AppUserParams>({
    username: '',
    email: '',
    phoneNumber: '',
    status: '',
  })
  const [messagesData, setMessagesData] = useState<MessagesData>({
    messages: [],
    totalMessages: 0,
  })
  const [formDisabledState, setFormDisabledState] = useState(true)
  const lifeCycleRef = useComponentLifeCycleState()

  useEffect(() => {
    document.title = `View user #${routeUserId} | i-i`
  }, []) //eslint-disable-line

  useEffect(() => {
    fetchUser()
  }, []) //eslint-disable-line

  useEffect(() => {
    fetchUserMessages()
  }, []) //eslint-disable-line

  async function fetchUser() {
    setLoadingState(true)
    const user = await getUser(routeUserId)
    if (lifeCycleRef.current) {
      user && setUser(user)
      user &&
        setInitialUserData({
          username: user.username,
          email: user.email,
          phoneNumber: user.phoneNumber,
          status: user.status,
        })
    }
    setLoadingState(false)
  }

  async function fetchUserMessages() {
    setLoadingState(true)
    const userMessagesData = await getUserMessages(
      routeUserId,
      moment().subtract(6, 'days').toString(),
      moment().toString()
    )
    if (lifeCycleRef.current) {
      userMessagesData && setMessagesData(userMessagesData)
    }
    setLoadingState(false)
  }

  async function submitChanges(event: React.FormEvent<HTMLFormElement>) {
    setLoadingState(true)
    event.preventDefault()
    const result = await editUser(routeUserId, userParams)
    result && returnToUsers()
    setLoadingState(false)
  }

  async function setUserAsPremium(user: AppUser) {
    setLoadingState(true)
    await setAsPremiumUser(user)
    await fetchUser()
    setLoadingState(false)
  }

  async function removeUserFromPremium(user: AppUser) {
    setLoadingState(true)
    await unsetUserAsPremium(user)
    await fetchUser()
    setLoadingState(false)
  }

  function returnToUsers() {
    history.push('/dashboard/users')
  }

  return (
    <div className={styles.editUser}>
      <div className={styles.userActionButtons}>
        <span
          className={`${styles.premiumLabel} ${
            user?.isPremium ? styles.isPremium : styles.isNotPremium
          }`}
        >
          <i className="fas fa-circle"></i>{' '}
          {user?.isPremium ? 'Premium' : 'Not premium'}
        </span>
        {user?.isPremium ? (
          <WarningButton
            type="button"
            onClick={() => removeUserFromPremium(user)}
          >
            <i className="far fa-star"></i> REMOVE THIS USER FROM PREMIUM
          </WarningButton>
        ) : (
          <SuccessButton
            type="button"
            onClick={() => setUserAsPremium(user as AppUser)}
          >
            <i className="fas fa-star"></i> SET THIS USER AS PREMIUM
          </SuccessButton>
        )}
        {formDisabledState && (
          <PrimaryButton
            type="button"
            onClick={() => setFormDisabledState(false)}
          >
            <i className="fas fa-edit"></i> EDIT THIS USER
          </PrimaryButton>
        )}
      </div>
      <form className={styles.editUserForm} onSubmit={submitChanges}>
        <label htmlFor="username">User name</label>
        <InputText
          name="username"
          defaultValue={userParams.username}
          required
          onChange={setUserParams}
          placeholder="e.g Alexander Derulo"
          disabled={formDisabledState}
        />
        <label htmlFor="email">E-mail Address</label>
        <InputEmail
          name="email"
          defaultValue={userParams.email}
          required
          onChange={setUserParams}
          placeholder="e.g useremail@domain.com"
          disabled={formDisabledState}
        />
        <label htmlFor="phoneNumber">Phone number</label>
        <InputPhone
          name="phoneNumber"
          defaultValue={userParams.phoneNumber}
          onChange={setUserParams}
          required
          placeholder="Enter user phone here."
          disabled={formDisabledState}
        />
        <label htmlFor="status">Status</label>
        <InputText
          name="status"
          defaultValue={userParams.status}
          onChange={setUserParams}
          required
          placeholder="e.g What a nice day!"
          disabled={formDisabledState}
        />
        <div className={styles.editUserFormButtonsContainer}>
          <SecondaryButton type="button" onClick={returnToUsers}>
            <i className="fas fa-times-circle"></i> CANCEL
          </SecondaryButton>
          <PrimaryButton disabled={formDisabledState}>
            <i className="fas fa-save"></i> SAVE CHANGES
          </PrimaryButton>
        </div>
      </form>
      <MessagesChart type="user-messages" data={messagesData} />
    </div>
  )
}
