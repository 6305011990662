import { useContext, useEffect } from 'react'
import twcLogo from '../../assets/login_logo.png'
import { appContext } from '../../context/context'
import { useInputChangeHandler } from '../../hooks/hooks'
import { LoginFormData } from '../../models/login'
import { logIn } from '../../services/auth-service/auth-service'
import { PrimaryButton } from '../shared/buttons/buttons'
import { InputEmail, InputPassword } from '../shared/inputs/inputs'
import ParticlesBackground from '../shared/particles-background/particles-background'
import styles from './login.module.css'

export default function Login() {
  const { setLoadingState, setUserData, toggleAuthStatus } = useContext(
    appContext
  )
  const [formData, setFormData] = useInputChangeHandler<LoginFormData>({
    email: '',
    password: '',
  })

  useEffect(() => {
    document.title = 'Log in | i-i'
  }, [])

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    setLoadingState(true)
    event.preventDefault()
    const user = await logIn(formData)
    user && setUserData(user)
    user && toggleAuthStatus(true)
    setLoadingState(false)
  }

  return (
    <div className={styles.login}>
      <div className={styles.loginFormContainer}>
        <h1 className={styles.loginFormTitle}>Log in to Dashboard</h1>
        <form className={styles.loginForm} onSubmit={handleSubmit}>
          <label htmlFor="email">E-mail Address:</label>
          <InputEmail
            name="email"
            placeholder="e.g myemail@mydomain.com"
            required
            onChange={setFormData}
          />
          <label htmlFor="password">Password:</label>
          <InputPassword
            name="password"
            placeholder="Enter your password here."
            required
            onChange={setFormData}
          />
          <PrimaryButton>
            <i className="fas fa-sign-in-alt"></i> LOG IN
          </PrimaryButton>
        </form>
      </div>
      <img
        src={twcLogo}
        alt="The Whisper Company Logo"
        className={styles.companyLogo}
      />
      <ParticlesBackground />
    </div>
  )
}
