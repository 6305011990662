import styles from './buttons.module.css'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export function PrimaryButton(props: ButtonProps) {
  return (
    <button
      type={props.type}
      className={`${styles.button} ${styles.primaryButton} ${props.className}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

export function SecondaryButton(props: ButtonProps) {
  return (
    <button
      type={props.type}
      className={`${styles.button} ${styles.secondaryButton} ${props.className}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

export function SuccessButton(props: ButtonProps) {
  return (
    <button
      type={props.type}
      className={`${styles.button} ${styles.successButton} ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

export function WarningButton(props: ButtonProps) {
  return (
    <button
      type={props.type}
      className={`${styles.button} ${styles.warningButton} ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

export function DangerButton(props: ButtonProps) {
  return (
    <button
      type={props.type}
      className={`${styles.button} ${styles.dangerButton} ${props.className}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

interface AnchorButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {}

export function AnchorButton(props: AnchorButtonProps) {
  return (
    <a
      href={props.href}
      rel="noopener noreferrer"
      className={`${styles.button} ${styles.anchorButton} ${props.className}`}
      target={props.target}
    >
      {props.children}
    </a>
  )
}
