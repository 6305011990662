import styles from './table.module.css'

type TableProps = {
  heads: string[]
  children: (
    rowStyle: string,
    cellStyle: string,
    actionCellStyle: string
  ) => React.ReactNode
}
export default function Table(props: TableProps) {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            {props.heads.map((head, index) => (
              <th className={styles.tableHead} key={index}>
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.children(
            styles.dataRow,
            styles.dataRowCell,
            styles.actionCell
          )}
        </tbody>
      </table>
    </div>
  )
}
