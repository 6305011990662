import Tippy from '@tippyjs/react'
import { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { appContext } from '../../../context/context'
import { useComponentLifeCycleState } from '../../../hooks/hooks'
import { Admin } from '../../../models/admin'
import {
  deleteAdmin,
  getAdmins,
} from '../../../services/admins-service/admins-service'
import { PrimaryButton } from '../../shared/buttons/buttons'
import Table from '../../shared/table/table'
import styles from './admins.module.css'

export default function Admins() {
  const { setLoadingState } = useContext(appContext)
  const [adminsList, setAdminsList] = useState<Admin[]>([])
  const adminsRoute = useRouteMatch().path
  const history = useHistory()
  const lifeCycleRef = useComponentLifeCycleState()

  useEffect(() => {
    document.title = 'Admins | i-i'
  }, [])

  useEffect(() => {
    fetchAdmins()
  }, []) //eslint-disable-line

  async function fetchAdmins() {
    setLoadingState(true)
    const adminsList = await getAdmins()
    if (lifeCycleRef.current) {
      adminsList && setAdminsList(adminsList)
    }
    setLoadingState(false)
  }

  async function removeAdmin(admin: Admin) {
    setLoadingState(true)
    const result = await deleteAdmin(admin)
    result && fetchAdmins()
    setLoadingState(false)
  }

  function goToAdminCreation() {
    history.push(`${adminsRoute}/create-admin`)
  }

  function editAdmin(admin: Admin) {
    history.push(`${adminsRoute}/show-admin/${admin.id}`)
  }

  return (
    <div className={styles.admins}>
      <PrimaryButton type="button" onClick={goToAdminCreation}>
        <i className="fas fa-plus"></i> CREATE ADMIN
      </PrimaryButton>
      <Table heads={['ID', 'User Name', 'E-mail Address', 'Actions']}>
        {(rowStyle, cellStyle, actionCellStyle) =>
          adminsList.map((admin) => (
            <tr className={rowStyle} key={admin.id}>
              <td className={cellStyle}>{admin.id}</td>
              <td className={cellStyle} onClick={() => editAdmin(admin)}>
                {admin.fullName}
              </td>
              <td className={cellStyle}>{admin.email}</td>
              <td className={actionCellStyle}>
                <Tippy
                  duration={0}
                  content={
                    <span className={styles.actionButtonTooltip}>
                      View admin
                    </span>
                  }
                >
                  <Link
                    to={`${adminsRoute}/show-admin/${admin.id}`}
                    className={`${styles.actionButton} ${styles.editButton}`}
                  >
                    <i className="fas fa-eye"></i>
                  </Link>
                </Tippy>
                <Tippy
                  duration={0}
                  content={
                    <span className={styles.actionButtonTooltip}>
                      Delete admin
                    </span>
                  }
                >
                  <button
                    type="button"
                    onClick={() => removeAdmin(admin)}
                    className={`${styles.actionButton} ${styles.deleteButton}`}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </Tippy>
              </td>
            </tr>
          ))
        }
      </Table>
    </div>
  )
}
