import { useState } from 'react'
import styles from './inputs.module.css'

interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLSelectElement> {
  children?: React.ReactNode
}

export function InputText(props: InputProps) {
  return (
    <span className={styles.inputContainer}>
      <input
        type="text"
        name={props.name}
        className={`${styles.input} ${styles.inputText}`}
        placeholder={props.placeholder}
        required={props.required}
        defaultValue={props.defaultValue}
        pattern={props.pattern}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <i className={`${styles.inputIcon} fas fa-font`}></i>
    </span>
  )
}

export function InputEmail(props: InputProps) {
  return (
    <span className={styles.inputContainer}>
      <input
        type="email"
        name={props.name}
        className={`${styles.input} ${styles.inputEmail}`}
        placeholder={props.placeholder}
        required={props.required}
        defaultValue={props.defaultValue}
        pattern={props.pattern}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <i className={`${styles.inputIcon} fas fa-at`}></i>
    </span>
  )
}

export function InputPassword(props: InputProps) {
  const [passwordIsShown, setPasswordVisibility] = useState<boolean>()
  return (
    <span className={styles.inputContainer}>
      <input
        type={passwordIsShown ? 'text' : 'password'}
        name={props.name}
        className={`${styles.input} ${styles.inputPassword}`}
        placeholder={props.placeholder}
        required={props.required}
        defaultValue={props.defaultValue}
        pattern={props.pattern}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <i
        className={`${styles.inputIcon} fas fa-${
          passwordIsShown ? 'eye' : 'eye-slash'
        }`}
        style={{ cursor: 'pointer' }}
        onClick={() => setPasswordVisibility((prevState) => !prevState)}
      ></i>
    </span>
  )
}

export function InputPhone(props: InputProps) {
  return (
    <span className={styles.inputContainer}>
      <input
        type="phone"
        name={props.name}
        className={`${styles.input} ${styles.inputPhone}`}
        placeholder={props.placeholder}
        required={props.required}
        defaultValue={props.defaultValue}
        pattern={props.pattern}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <i className={`${styles.inputIcon} fas fa-phone`}></i>
    </span>
  )
}

export function InputDate(props: InputProps) {
  return (
    <input
      type="date"
      name={props.name}
      className={`${styles.input} ${styles.inputDate}`}
      required={props.required}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      disabled={props.disabled}
    />
  )
}

export function InputSelect(props: InputProps) {
  return (
    <span className={styles.inputContainer}>
      <select
        name={props.name}
        className={styles.select}
        required={props.required}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        {props.children}
      </select>
      <i className={`${styles.inputIcon} fas fa-chevron-down`}></i>
    </span>
  )
}
