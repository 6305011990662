import Swal from 'sweetalert2'
import styles from './feedback-service.module.css'

const modal = Swal.mixin({
  reverseButtons: true,
  buttonsStyling: false,
  customClass: {
    container: styles.modalContainer,
    content: styles.modalContent,
    popup: styles.modal,
    confirmButton: `${styles.button} ${styles.confirmButton}`,
    cancelButton: `${styles.button} ${styles.cancelButton}`,
    denyButton: `${styles.button} ${styles.denyButton}`,
    title: styles.modalContent,
    input: styles.modalInput,
    validationMessage: styles.modalInputValidationMsg,
    footer: styles.modalFooter,
  },
  allowEscapeKey: false,
  allowOutsideClick: false,
  cancelButtonText: 'CANCEL',
})

export function show2FAQR(qrSrc: string, base32token: string) {
  return modal.fire({
    title: 'For your security...',
    text:
      'Please, download Google Authenticator App on your phone and scan this QR code with it. Then, save the token placed below to recover your access in case you loss your phone.',
    imageUrl: qrSrc,
    imageWidth: 200,
    imageHeight: 200,
    footer: `<small><strong>SECURITY TOKEN:</strong> ${base32token}</small>`,
    confirmButtonText: 'OK, GOT IT.',
    showCancelButton: true,
    cancelButtonText: 'CANCEL',
  })
}

export function show2FAModal(cancellable = false) {
  return modal.fire({
    icon: 'question',
    iconColor: 'var(--warning-color)',
    iconHtml: '<i class="fas fa-lock"></i>',
    title: 'Two-Factor Authentication (2FA)',
    text:
      'Please enter the code you see in Google Authenticator App to complete the process.',
    input: 'text',
    inputAutoTrim: true,
    inputValidator: (code) => {
      if (!code) {
        return 'The code field cannot be empty'
      } else {
        const numberRegex = /^\d{6}$/
        if (numberRegex.test(code)) {
          return null
        } else {
          return 'The code field can only contain a six-digit number. Letters are not allowed.'
        }
      }
    },
    inputPlaceholder: 'e.g 000111',
    confirmButtonText: 'CONTINUE',
    showCancelButton: cancellable,
    footer: `<small>The code is renewed every 30 seconds, so make sure you put it in on time!</small>`,
  })
}

export function showToast(
  type: 'success' | 'error' | 'warning',
  message: string
) {
  return Swal.fire({
    icon: type,
    text: message,
    toast: true,
    showConfirmButton: false,
    showCancelButton: false,
    position: 'top',
    customClass: {
      container: styles.toastContainer,
      content: styles.toastContent,
      icon: styles.toastIcon,
      popup: styles.toast,
      loader: styles.toastLoader,
    },
    timer: 5000,
    width: '300px',
  })
}

export function showModal(
  type: 'success' | 'warning' | 'question' | 'error' | 'info',
  title: string,
  message: string,
  cancellable: boolean
) {
  return modal.fire({
    icon: type,
    text: message,
    title,
    confirmButtonText: 'CONFIRM',
    showCancelButton: cancellable,
  })
}

export function showPasswordChangeModal() {
  return modal.fire({
    icon: 'question',
    iconColor: 'var(--warning-color)',
    iconHtml: '<i class="fas fa-key"></i>',
    title: 'Change password',
    html: `<div class=${styles.passwordInputsContainer}>
    <label for="oldpass">New password</label>
    <input id="oldpass" type="password" class=${styles.modalInput} />
    <label for="newpass">Confirm new password</label>
    <input id="newpass" type="password" class=${styles.modalInput} />
    </div>`,
    footer: `<small>Remember to use a strong password to keep your account secure.</small>`,
    preConfirm: () => {
      const passwords = [
        (document.getElementById('oldpass') as HTMLInputElement).value,
        (document.getElementById('newpass') as HTMLInputElement).value,
      ]
      const [oldpass, newpass] = passwords
      if (oldpass && newpass) {
        if (oldpass === newpass) {
          return newpass
        } else {
          modal.showValidationMessage(
            'Both passwords are not equal. Try again!'
          )
        }
      } else {
        modal.showValidationMessage('Password fields cannot be empty.')
      }
    },
    focusConfirm: false,
    confirmButtonText: 'SET NEW PASSWORD',
    showCancelButton: true,
  })
}
